export enum Status {
    CALM = 'calm',
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
}

export interface GetUserInfo {
    id: number;
    firstName: string;
    lastName: string;
}

export interface Profile {
    vkontakte_id: string | null;
    first_name: string | null;
    last_name: string | null;
}