import axios from "axios";

import { API_URL } from '../http/index';

import { PurchaseFormData } from "../redux/purchase/types";


export default class PurchaseService {
    static async activate(formData: PurchaseFormData) {
        const res = await axios.post(
            `${API_URL}telegram-web-app/v1/vk/register/purchase`,
            formData,
        )
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
}