import style from './Home.module.scss';

// VK
import vkBridge from '@vkontakte/vk-bridge';

// Components
import Blogs from '../../components/home/blogs/Blogs';
import Packages from '../../components/home/packages/Packages';
import { useEffect, useState } from 'react';
import { GetUserInfo } from '../../types/types';


const Home = () => {
    const [user, setUser] = useState<GetUserInfo | void>();

    useEffect(() => {
        if (!user) {
          vkBridge.send('VKWebAppGetUserInfo')
          .then((data) => { 
            if (data.id) {
                setUser({
                    id: data.id,
                    firstName: data.first_name,
                    lastName: data.last_name,
                })
            }    
          })
        }
    }, [user])

    return (
        <div className={style.home_page}>
            <div className={style.home_page__welcome}>
                <p>Здравствуйте, {user?.firstName}!</p>
            </div>
            <Blogs />
            <Packages title='Консультации, которые подойдут именно Вам' />
        </div>
    )
}

export default Home;