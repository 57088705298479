import style from './ServicePay.module.scss';

import { useEffect, useState } from 'react';

// Ant Design
import { message as antdMessage } from 'antd';
import { Checkbox, CheckboxProps } from 'antd';

// Router
import { useParams, useNavigate } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { setPackageByAlias } from '../../redux/packages/slice';
import { PromoCodeFormData } from '../../redux/promocode/types';
import { selectPromoCode } from '../../redux/promocode/selectors';
import { requestActivatePromoCode } from '../../redux/promocode/asyncActions';

// Component
import Spinner from '../../components/utils/spinner/Spinner';

// VK
import vkBridge from '@vkontakte/vk-bridge';

// Types
import { GetUserInfo, Status } from '../../types/types';
import { selectPackages } from '../../redux/packages/selectors';
import { setPromocodeIsNull } from '../../redux/promocode/slice';
import { selectPurchase } from '../../redux/purchase/selectors';
import { PurchaseFormData } from '../../redux/purchase/types';
import { requestPurchase } from '../../redux/purchase/asyncActions';
import { setPurchaseIsNull } from '../../redux/purchase/slice';


const ServicePay = () => {
    // Navigate
    const navigate = useNavigate();
    const { activePackage } = useSelector(selectPackages);

    // User
    const [user, setUser] = useState<GetUserInfo | void>();

    useEffect(() => {
        if (!user) {
            vkBridge.send('VKWebAppGetUserInfo')
                .then((data) => {
                    if (data.id) {
                        setUser({
                            id: data.id,
                            firstName: data.first_name,
                            lastName: data.last_name,
                        })
                    }
                })
        }
    }, [user])

    // Get Path
    const { alias, verbose_name, price } = useParams();

    // Redux
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (alias) {
            dispatch(setPackageByAlias({alias: alias}))
        }
    }, [dispatch, alias])

    // Promocode
    const [promocode, setPromocode] = useState<string>('');

    // Show Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();
    const { status, message, promo_package } = useSelector(selectPromoCode);

    // Activate Promocode
    const handlerActivatePromocode = (e: any) => {
        e.preventDefault();
        e.target.disabled = true;

        if (promo_package) {
            messageApi.info('Вы уже активировали промокод', 2);
            e.target.disabled = false;
            return;
        }

        if (promocode.length === 0) {
            messageApi.error('Пожалуйста введите промокод', 2);
            e.target.disabled = false;
            return;
        }

        if (alias) {
            const formData: PromoCodeFormData = {
                promocode: promocode,
                service: alias,
            }
            dispatch(requestActivatePromoCode(formData));
        }
        e.target.disabled = false;
    }

    useEffect(() => {
        if (status !== Status.CALM && message.length > 0) {
            if (status === Status.SUCCESS) {
                messageApi.success(message, 2);
                return;
            }
            if (status === Status.ERROR) {
                messageApi.error(message, 2);
                return;
            }
        }
    }, [status, message, messageApi])

    // Button
    const [acceptRight, setAcceptRight] = useState(false);

    // Purchase
    const { messagePurchase, statusPurchase } = useSelector(selectPurchase);

    // Payment Handler
    const payment_handler = () => {
        if (!acceptRight) {
            messageApi.info('Пожалуйста примите пользовательское соглашение', 2);
            return;
        }
        if (activePackage && acceptRight) {
            vkBridge.send('VKWebAppOpenPayForm', {
                'app_id': 52087294,
                "action": "pay-to-user",
                "params": {
                    amount: promo_package ? promo_package.price : activePackage.price,
                    user_id: 638746546,
                }
            })
            .then((data: any) => {
                if (data.status === 'success' && user) {
                    // Register Purchase
                    const formDataPurchase: PurchaseFormData = {
                        package_alias: activePackage.alias,
                        vkontakte_id: user.id,
                    }
                    dispatch(requestPurchase(formDataPurchase));
                }
            })
            .catch((error: any) => {
                // Ошибка
                alert(`
                    type: ${error.type},
                    error_type: ${error.data.error_type},
                `);
            })
        }
    }

    useEffect(() => {
        if (statusPurchase === Status.SUCCESS && messagePurchase.length > 0) {
            messageApi.success(messagePurchase, 2);
            navigate(`/home/`);
        }

        if (statusPurchase === Status.ERROR && messagePurchase.length > 0) {
            messageApi.error(messagePurchase, 2);
        }

        return () => {
            dispatch(setPurchaseIsNull());
            dispatch(setPromocodeIsNull());
        }
    }, [statusPurchase, messagePurchase, messageApi, dispatch])

    const onChange: CheckboxProps['onChange'] = (e) => {
        setAcceptRight(!acceptRight);
    };

    return (
        <div className={style.service_pay}>
            {contextHolder}
            {alias && verbose_name && price
                ? (
                    <>
                        <h1 className={style.service_pay__title}>Оплата</h1>
                        <div className={style.service_pay__service_info}>
                            <h2>Оплата услуги: {verbose_name}</h2>
                        </div>
                        <div className={style.service_pay__promocode}>
                            <form>
                                <input
                                    type="text"
                                    value={promocode}
                                    onChange={(e) => setPromocode(e.target.value)} />
                                <button onClick={handlerActivatePromocode}>Применить</button>
                            </form>
                        </div>
                        <div className={style.service_pay__personal_info}>
                            <Checkbox onChange={onChange} />
                            <p>Даю согласие на обработку персональных данных, соглашаюсь с <a href='https://my.astars.online/media/content/company/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0-%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8-ASTARS_RU1.pdf'>Политикой конфиденциальности</a> и принимаю условия <a href='https://my.astars.online/media/content/company/%D0%9E%D0%A4%D0%95%D0%A0%D0%A2%D0%90_%D0%9F%D0%A0%D0%90%D0%92%D0%98%D0%9B%D0%90_%D0%9F%D0%A0%D0%95%D0%94%D0%9E%D0%A1%D0%A2%D0%90%D0%92%D0%9B%D0%95%D0%9D%D0%98%D0%AF_%D0%A3%D0%A1%D0%9B%D0%A3%D0%93_ASTARS_05_05_6_ajHq8t5.pdf'>Пользовательского соглашения (Оферты)</a></p>
                        </div>
                        <div
                            className={style.service_pay__btn}
                        >
                            <button
                                onClick={payment_handler}
                                className={style.main_btn}
                            >{`Купить ${promo_package ? promo_package.price : activePackage?.price} ₽`}</button>
                        </div>
                    </>
                )
                : <Spinner />}
        </div>
    )
}

export default ServicePay;