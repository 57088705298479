import style from './Welcome.module.scss';

// React
import { useEffect, useState } from 'react';

// Router
import { useNavigate } from 'react-router-dom';

// Redux
import { useAppDispatch } from '../../redux/store';
import { AuthFormData } from '../../redux/auth/types';
import { requestAuth } from '../../redux/auth/asyncActions';

// Image
import imgOne from '../../assets/img/welcome/1.svg';
import imgTwo from '../../assets/img/welcome/2.svg';
import imgFour from '../../assets/img/welcome/4.svg';
import imgThree from '../../assets/img/welcome/3.svg';
import welcomeLogo from '../../assets/img/welcome/logo.png';

// VK
import vkBridge from '@vkontakte/vk-bridge';

// Components
import ButtonMain from '../../components/buttons/Buttons';
import { GetUserInfo, Profile } from '../../types/types';


const Welcome = () => {
    // Navigate
    const navigate = useNavigate();

    const [user, setUser] = useState<GetUserInfo | void>();
    const [isClearLocalStorage, setIsClearLocalStorage] = useState<boolean>(false);

    // Redux
    const dispatch = useAppDispatch();

    // Get Token
    if (!isClearLocalStorage) {
        localStorage.clear();
        setIsClearLocalStorage(true);
    }
    const token = localStorage.getItem('token');


    useEffect(() => {
        if (!user) {
          vkBridge.send('VKWebAppGetUserInfo')
          .then((data) => { 
            if (data.id) {
                setUser({
                    id: data.id,
                    firstName: data.first_name,
                    lastName: data.last_name,
                })
            }    
          })
        }
      }, [user])

    useEffect(() => {
        if (!token && user?.id) {
            const formData: Profile = {
                'vkontakte_id': user.id ? `${user.id}` : null,
                'first_name': user.firstName ? user.firstName : null,
                'last_name': user.lastName ? user.lastName : null,
            }
            dispatch(requestAuth(formData));
        }
    }, [token, dispatch, user])

    const handleClickContinue = () => {
        navigate('/home');
    }
    return (
        <div className={style.welcome_page}>
            <div className={style.welcome_page__logo}>
                <img src={welcomeLogo} alt="welcome-logo" />
            </div>

            <div className={style.welcome_info__services}>
                <ul>
                    <li>
                        <div className={style.image}>
                            <img src={imgOne} alt="check-mark" />
                        </div>
                        <p>Поймете своё предназначение</p>
                    </li>
                    <li>
                        <div className={style.image}>
                            <img src={imgTwo} alt="check-mark" />
                        </div>
                        <p>Скорректируете планы в бизнесе</p>
                    </li>
                    <li>
                        <div className={style.image}>
                            <img src={imgThree} alt="check-mark" />
                        </div>
                        <p>Найдёте сильные стороны вашего ребёнка</p>
                    </li>
                    <li>
                        <div className={style.image}>
                            <img src={imgFour} alt="check-mark" />
                        </div>
                        <p>Поймете как избавиться от неудач</p>
                    </li>
                </ul>
            </div>

            <div onClick={handleClickContinue} className={style.welcome_page__btn}>
                <ButtonMain text='Продолжить' />
            </div>
        </div>
    )
}

export default Welcome;