import style from './Profile.module.scss';

// React
import { useEffect, useState } from 'react';

// Router
import { Link } from 'react-router-dom';

// Ant Design
import { UserOutlined } from '@ant-design/icons';

// VK
import vkBridge from '@vkontakte/vk-bridge';

// Redux
import { useSelector } from 'react-redux';
import { GetUserInfo, Status } from '../../types/types';
import { useAppDispatch } from '../../redux/store';
import { selectMyServices } from '../../redux/my-services/selectors';
import { requestMyServices } from '../../redux/my-services/asyncActions';


const Profile = () => {
    const [user, setUser] = useState<GetUserInfo | void>();

    useEffect(() => {
        if (!user) {
          vkBridge.send('VKWebAppGetUserInfo')
          .then((data) => { 
            if (data.id) {
                setUser({
                    id: data.id,
                    firstName: data.first_name,
                    lastName: data.last_name,
                })
            }    
          })
        }
    }, [user])

    // Redux
    const dispatch = useAppDispatch();
    const { status, my_services } = useSelector(selectMyServices);

    useEffect(() => {
        if (!my_services.length && status === Status.CALM) {
            dispatch(requestMyServices());
        }
    }, [my_services.length, dispatch, status])

    return (
        <div className={style.profile}>
            <div className={style.profile__info}>
                <div className={style.profile__info__logo_block1}>
                    <div className={style.profile__info__logo_block2}>
                        <div className={style.profile__info__logo_block3}>
                            <div className={style.profile__info__logo}>
                                <UserOutlined />
                            </div>
                        </div>
                    </div>
                </div>
                <p className={style.profile__info_text}>{user?.id}</p>
                <div className={style.profile__info_name}>
                    <h1>{user?.firstName}</h1>
                </div>
                <Link to={'/home/services'} className={style.profile__active_services}>
                    <p className={style.profile__active_services__name}>Активный услуги</p>
                    <div className={style.profile__active_services__counts}>
                        <p>{my_services.length}</p>
                    </div>
                </Link>
            </div>
            <div className={style.profile__menu}>
                <Link to={'/home/our-analysis'}>
                    <div className={style.profile__menu__item}>
                        <p>Ваши разборы</p>
                    </div>
                </Link>
                <Link to={'/home/support'}>
                    <div className={style.profile__menu__item}>
                        <p>Поддержка</p>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Profile;