import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../../types/types';
import { PurchaseSliceState } from './types';
import { requestPurchase } from './asyncActions';


const initialState: PurchaseSliceState = {
    statusPurchase: Status.CALM as Status,
    messagePurchase: '',
    data: null,
}

const purchaseSlice = createSlice({
    name: 'purchase',
    initialState,
    reducers: {
        setPurchaseIsNull: (state) => {
            state.data = null;
            state.statusPurchase = Status.CALM;
            state.messagePurchase = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(requestPurchase.pending, (state) => {
            state.statusPurchase = Status.LOADING;
        })
        builder.addCase(requestPurchase.fulfilled, (state, action) => {
            if (action.payload) {
                state.statusPurchase = Status.SUCCESS;
                state.messagePurchase = 'Покупка успешно завершена';
                state.data = action.payload;
            } else {
                state.statusPurchase = Status.ERROR;
                state.messagePurchase = 'Произошла ошибка при покупке';
            }
        })
        builder.addCase(requestPurchase.rejected, (state) => {
            state.statusPurchase = Status.ERROR;
        })
    }
})

export const { setPurchaseIsNull } = purchaseSlice.actions;

export default purchaseSlice.reducer;